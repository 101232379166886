import { ReactNode } from "react";
import { Stack } from "@storyofams/react-ui";

interface WrapperProps {
  children: ReactNode;
}

export const Wrapper = ({ children }: WrapperProps) => {
  return (
    <Stack
      space={[4, 4, 5, 5]}
      maxWidth="520px"
      width="100%"
      px={2}
      mx="auto"
      flex={1}
      pt={{ all: 5, md: 8 }}
      pb={{ all: 5, md: 15 }}
      flexDirection="column"
    >
      {children}
    </Stack>
  );
};
