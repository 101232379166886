import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@storyofams/react-ui";
import { NextPage, GetStaticProps } from "next";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { useIntl, defineMessages } from "react-intl";
import * as Yup from "yup";

import {
  LegacyButton,
  Input,
  Divider,
  Text,
  Checkbox,
  Heading,
  Seo,
  PersistentToast,
} from "~components";
import { useCustomer } from "~hooks";
import { withLayout, globalStaticProps, handlePromise } from "~lib";
import { Wrapper } from "~components/login";
import { CustomerAccessTokenCreateInput } from "~lib/shopify/sdk";

const messages = defineMessages({
  login: "Login",
  fillOutUserDetails: "Fill out your user details to sign into your account.",
  forgotPassword: "Forgot your password?",
  noAccount: "No account yet?",
  signup: "Signup",
  staySignedIn: "Stay signed in",
  email: "Your email",
  password: "Your password",
  alertMessage: "Something went wrong",
});

const schema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

const LoginPage: NextPage = () => {
  const intl = useIntl();
  const router = useRouter();
  const { login, customer } = useCustomer();
  const [persistentError, setPersistentError] = useState<string>();

  useEffect(() => {
    if (customer?.id) router.push("/");
  }, [customer]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<CustomerAccessTokenCreateInput>({
    resolver: yupResolver(schema),
  });

  const handleLogin = async (input: CustomerAccessTokenCreateInput) => {
    const [res, err] = await handlePromise(login.mutateAsync(input));

    if (!res?.customerAccessTokenCreate?.customerAccessToken || err) {
      if (process.env.NODE_ENV === "development") console.error(err);
      setPersistentError(
        err?.message ?? intl.formatMessage(messages.alertMessage)
      );
    }
  };

  return (
    <Wrapper>
      <Seo title="Login" />

      <Box>
        <Heading as="h1" variant={["h3", "h2"]} mb={[4, 3]} color="grey900">
          {intl.formatMessage(messages.login)}
        </Heading>

        <Text fontSize={[2, 1.75]} color="grey700">
          {intl.formatMessage(messages.fillOutUserDetails)}
        </Text>
      </Box>

      {persistentError && (
        <PersistentToast msg={persistentError} type="error" />
      )}

      <Box as="form" onSubmit={handleSubmit(handleLogin)} width="100%">
        <Input
          required
          type="email"
          label={intl.formatMessage(messages.email)}
          {...register("email")}
          error={errors?.email?.message}
          mb={{ all: 3, md: 4 }}
        />

        <Input
          required
          type="password"
          label={intl.formatMessage(messages.password)}
          {...register("password")}
          error={errors?.password?.message}
          mb={1}
        />
        <LegacyButton
          color="grey900"
          to="/forgot-password"
          mb={{ all: 3, md: 2.5 }}
          variant="link-md"
          fontWeight={["normal", "500"]}
        >
          {intl.formatMessage(messages.forgotPassword)}
        </LegacyButton>

        <Checkbox
          label={intl.formatMessage(messages.staySignedIn)}
          mb={{ all: 4, md: 5 }}
        />

        <LegacyButton
          isLoading={isSubmitting}
          variant="primary"
          width="100%"
          type="submit"
        >
          {intl.formatMessage(messages.login)}
        </LegacyButton>
      </Box>
      <Box py={[1, 3]}>
        <Divider />
      </Box>
      <Box>
        <Heading
          mb={3}
          variant="h3"
          as="h6"
          color="grey900"
          textAlign={["left", "center"]}
        >
          {intl.formatMessage(messages.noAccount)}
        </Heading>
        <LegacyButton variant="outline" to="/signup" width="100%" mb={[3, 1]}>
          {intl.formatMessage(messages.signup)}
        </LegacyButton>
      </Box>
    </Wrapper>
  );
};

export default withLayout(LoginPage);

export const getStaticProps: GetStaticProps = globalStaticProps(async () => ({
  props: {},
}));
